<template>
  <div id="cotizacion" class="vista-nuevo">
    <div class="card__header"><span>Nuevo caso</span></div>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" v-on:click="LigaCliente(true)">Datos personales</button>
        <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" v-on:click="LigaCliente(false)">Destino</button>

        <!-- aportacion voluntaria tab -->
		<button 
            v-if="hasAportacion == 'Si'"
            v-on:click="LigaCliente(false)"
			class="nav-link" 
			id="nav-profile-tab" 
			data-bs-toggle="tab" 
			data-bs-target="#nav-aportacion" 
			type="button" role="tab" 
			aria-controls="nav-aportacion" 
			aria-selected="false">Aportación Voluntaria
		</button>
        <!-- aportacion voluntaria tab -->

      </div>
    </nav>
    <form>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show back active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <div class="row w-full">
			<div class="col-md-3">
              <label class="span-placeholder">Correo electrónico</label>
              <input class="vs-input" v-validate="'required|email'" v-model="email" name="email" />
              <span class="text-danger text-sm" v-show="errors.has('email')">Campo requerido, debe ser un email válido</span>   
              <span class="text-danger text-sm" v-if="emailState">*No se permite ingresar correos con dominio de SOC</span>       
            </div>
            <div class="col-md-3">
              <label class="span-placeholder">Nombre</label>
              <input 
			  	class="vs-input" 
				v-validate="'required|alpha_spaces'" 
				v-model="nombre" 
				name="nombres"
				v-on:keypress="soloLetras($event)"/>
              <span class="text-danger text-sm" v-show="errors.has('nombres')">Campo requerido, solo caracteres</span>
            </div> 
			<div class="col-md-3">
              <label class="span-placeholder">Segundo nombre</label>
              <input class="vs-input"  maxlength="20" v-model="segNombre" name="segNombres" v-on:keypress="soloLetras($event)" />
              <span class="text-danger text-sm" v-show="errors.has('segNombres')">Campo requerido, solo caracteres</span>
            </div> 
            <div class="col-md-3">
              <label class="span-placeholder"> Apellido Paterno</label>
              <input class="vs-input" v-validate="'required|alpha_spaces'" v-model="apellidoPaterno" name="apellidoPaterno" v-on:keypress="soloLetras($event)" />
              <span class="text-danger text-sm" v-show="errors.has('apellidoPaterno')">Campo requerido, solo caracteres</span>        
            </div> 
          </div>
          <div class="row w-full">
			<div class="col-md-3">
              <label class="span-placeholder"> Apellido Materno</label>
              <input class="vs-input"  v-model="apellidoMaterno" name="apellidoMaterno" v-on:keypress="soloLetras($event)"/>
              <!--	<span class="text-danger text-sm" v-show="errors.has('apellidoMaterno')">Campo requerido, solo caracteres</span> -->
            </div>    
            <div class="col-md-3">
              <label class="span-placeholder">Fecha de nacimiento</label>
              <input class="vs-input" type="date" v-model="fechaNacimiento" name="fechaNacimiento" />
              <span class="text-danger text-sm" v-show="errors.has('fechaNacimiento')">Campo requerido</span>              
            </div>  
            <div class="col-md-3">
              <label class="span-placeholder">Teléfono celular (10 dígitos)</label>
              <input class="vs-input" v-model="telefono" name="telefono" v-validate="'required'" minlength="10" maxlength="10" v-on:keypress="isNumber($event)"/>
              <span class="text-danger text-sm" v-show="errors.has('telefono')">El campo debe tener 10 caracteres</span>          
            </div> 
            <div class="col-md-3">
              <span class="span-placeholder" for="estados">Estado</span>
                <select name="cars" id="estados" v-model="caso.estado" class="vs-input" >
                <option :value='tipo.Id' v-for="tipo in estados"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
          </div> 	
        </div>
		
		<div class="liga_cliente" v-if="ligaCliente">
			<div class="card__header"><span>Liga del cliente</span></div>
			<div class="row w-full">
				<div class="col-md-3">
					<span class="span-placeholder">CURP</span>
					<input class="vs-input" v-model="curp" name="curp" v-validate="'min:18|max:18'" v-mask="'AAAA######AAAAAANN'" value=""  onkeyup="javascript:this.value=this.value.toUpperCase();"/>
					<span class="text-danger span-placeholder" v-show="errors.has('curp')">El campo debe tener 18 caracteres</span>
				</div> 
			</div>
			<div class="row w-full">
				<div class="col-md-10">
					<p>La liga es un enlace que se le envía al cliente para que este pueda proporcionar todos los datos necesarios para crear su pedido en SISEC, subir documentos pendientes y poder visualizar el estatus de su pedido.</p>
					<p><strong>*si desea que esta liga se le envíe al cliente favor de llenar el campo de la CURP.</strong></p>
				</div>
			</div>
		</div>
			
        <div class="tab-pane show back fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">

			<div class="row w-full">
				<div class="col-md-8">
					<span class="span-placeholder" for="lineaNegocio">Línea de negocio</span>
					<select
						v-model="caso.PrecalificacionTipo" 
						name="lineaNegocio" 
						id="lineaNegocio" 
						class="v-select vs--single  vs-input--input">
						<option :value="tipo.Id" v-for="tipo in productoTipoList" :key="tipo.Id">{{ tipo.Nombre }}</option>
					</select>
				</div>
			</div>

          <div class="row w-full">
            <div class="col-md-8">
              <label class="span-placeholder" for="destinos">Destino</label>
              <select name="cars" id="destinos" v-model="caso.destino" class="vs-input" @change="mostrarProgramas()">
                <option :value='tipo.Id' v-for="tipo in destinos"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>
              
          </div>
          <div class="row w-full">
            <div class="col-md-8" v-if="(caso.destino == 1 || caso.destino == 5 || caso.destino == 2 || caso.destino == 20 || caso.destino == 19 || caso.destino == 15) && caso.PrecalificacionTipo == 1">
              <label class="span-placeholder" for="Programas">Programas</label>
              <select name="cars" id="Programas" v-model="caso.programa" class="vs-input">
                <option :value='tipo.Id' v-for="tipo in programas"  :key="">{{tipo.Nombre}}</option>
              </select>
            </div>

            <Automotriz 
              :otro="otro"
			  :destinoAuto="caso.destino"
              :porcentajeMaximo="PorcentajeMaximo"
              v-on:updateValores="update($event)"
              v-if="caso.PrecalificacionTipo == 3 && (caso.destino == 41 || caso.destino == 42 || caso.destino == 43 || caso.destino == 45 || caso.destino == 46 || caso.destino == 47)" />
            <AdquisicionCofi 
				:programa="caso.programa" 
				:Destino="caso.destino" 
				:otro="otro" 
				v-if="(caso.destino == 1 || caso.destino == 19) && (caso.programa == 4 || caso.programa == 9 ) && caso.PrecalificacionTipo == 1" 
				v-on:updateValores="update($event)" 
				v-on:buscaBancos="cambiaBancos($event)"/>
            <Adquisicion 
				:otro="otro" 
				v-if="(caso.destino == 1 || caso.destino == 24 || caso.destino == 21 || caso.destino == 3 || caso.destino == 27 || caso.destino == 15 || caso.destino == 8 || caso.destino == 10 || caso.destino == 32 || caso.destino == 37 ) && (caso.programa==1 || caso.programa == 6) && caso.PrecalificacionTipo == 1" 
				v-on:updateValores="update($event)" 
				v-on:buscaBancos="cambiaBancos($event)"/>
            <Mejora 
				v-if="(caso.destino == 25 || caso.destino == 22 || caso.destino == 33 || caso.destino == 34 || caso.destino == 19) && caso.PrecalificacionTipo == 1" 
				v-on:updateValores="update($event)" 
				v-on:buscaBancos="cambiaBancos($event)"/>
            <TerrenoProgramas 
				:programa="caso.programa" 
				:otro="otro" 
				v-if="(caso.destino == 5 || caso.destino == 20 || caso.destino == 2) && (caso.programa == 4 || caso.programa == 9 ) && caso.PrecalificacionTipo == 1" 
				v-on:updateValores="update($event)" 
				v-on:buscaBancos="cambiaBancos($event)"/>
            <Terreno 
				:otro="otro" 
				:destino="caso.destino" 
				v-if="(caso.destino == 5 || caso.destino == 20 || caso.destino == 2) && (caso.programa==1 || caso.programa == 6) && caso.PrecalificacionTipo == 1" 
				v-on:updateValores="update($event)" 
				v-on:buscaBancos="cambiaBancos($event)"/>
          </div>

		   <div
		   	v-if="caso.PrecalificacionTipo == 1" 
		   	class="row w-full">
			<div class="col-md-4">
				<span class="text-sm" for="gastosNotariales">Gastos notariales</span>
				<select name="gastosNotariales" id="gastosNotariales" v-model="caso.gastosNotarial" class="v-select vs--single  vs-input--input">
				<option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
				</select>
			</div>

            <!-- aportacion voluntaria radio -->
            <div class="col-md-3">
                <span 
                    class="text-sm" 
                    for="hasAportacion">Aportación voluntaria
                </span>

                <div class="flex justify-between col-md-5 mt-2">
                    <div class="flex items-center">
                        <vs-radio v-model="hasAportacion" vs-name="hasAportacion" vs-value="No"></vs-radio>
                        <span class="span-placeholder">No</span>
                    </div>
                    <div class="flex items-center">
                        <vs-radio v-model="hasAportacion" vs-name="hasAportacion" vs-value="Si"></vs-radio>
                        <span class="span-placeholder">Si</span>
                    </div>
                </div>
            </div>
            <!-- aportacion voluntaria radio -->

			<!-- Ministraciones -->
			<div class="col-md-3" v-if="caso.destino == 2 || caso.destino == 8 || caso.destino == 20 || caso.destino == 21 || caso.destino == 10 || caso.destino == 32">
				<span 
				class="text-sm" 
				for="hasAportacion">Uso de Ministraciones
				</span>

				<div class="flex justify-between col-md-5 mt-2">
				<div class="flex items-center">
					<vs-radio 
					v-model="usoMinistraciones" 
					vs-name="usoMinistraciones" 
					vs-value="No"></vs-radio>

					<span class="span-placeholder">No</span>
				</div>
				<div class="flex items-center">
					<vs-radio 
					v-model="usoMinistraciones" 
					vs-name="usoMinistraciones" 
					vs-value="Si"></vs-radio>

					<span class="span-placeholder">Si</span>
				</div>
				</div>
			</div>          
			
			<div  v-if="usoMinistraciones== 'Si'">
				<div class="row w-full">
					<div class="col-md-4">
						<span class="span-placeholder" for="destinos">Plazo de construcción (meses)</span>
						<select name="cars" id="plazoContruccion" v-model="plazoContruccion" class="vs-input" @change="updatePeriodo()">
							<option :value="plazo.value" v-for="plazo in plazoMinistraciones" :key="">{{ plazo.label }}</option>
						</select>
					</div>									
					<div class="col-md-4">
						<span class="span-placeholder" for="destinos">Periodo de ministraciones</span>
						<select name="cars" id="periodo" v-model="periodo" class="vs-input" @change="updateCampos()">
							<option :value="periodo.value" v-for="periodo in periodoMinistraciones" :key="">{{ periodo.label }}</option>
						</select>
					</div>
				</div>
				<div class="row w-full">
					<div class="col-md-4" v-if="campos">
						<span class="span-placeholder" for="destinos">Porcentaje de ministraciones</span><br>
					</div>											
				</div>
				<div class="row w-full" v-if="campos">
					
					<!--<div class="flex items-center" id="porcentajesMinistracion"> -->
					<div class="col-md-1 flex items-center" id="porcentajesMinistracion" v-for="item in campos">

						<div id="inputFormRow" v-if="item <= 9">
								<span class="span-placeholder" for="destinos"> {{ item }}</span>
							<div class="flex items-center">												
								<input type="text" v-model="camposID.name[item]" class="form-control" v-on:blur="calculaPorcentaje()" style="padding-left:10%;" v-on:keypress="SoloNumero($event)"> %'
							</div>			
						</div>																																				
					</div>
					
				</div>
				<div class="row w-full" v-if="campos">
					<div class="col-md-1 flex items-center" id="porcentajesMinistracion2" v-for="item in campos"  v-if="item > 9">
						<div id="inputFormRow">
								<span class="span-placeholder" for="destinos"> {{ item }}</span>
							<div class="flex items-center">												
								<input type="text" v-model="camposID.name[item]" class="form-control" v-on:blur="calculaPorcentaje()" style="padding-left:10%;" v-on:keypress="SoloNumero($event)"> %'
							</div>			
						</div>																							
					</div>
				</div>
				<span id="alert" class="text-danger span-placeholder" style="display: none; color: red; ">La suma de los porcentajes de ministraciones tiene que ser 100%</span>
			</div>

		 </div>	

          <div class="row w-full">           
            <div class="col-md-8">
              <label class="span-placeholder">Bancos</label>
            <!--  <v-select  v-model="caso.bancos" multiple :closeOnSelect="false" :options="bancosEjecutivo" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>-->

			   <v-select v-model="caso.bancos" multiple :closeOnSelect="false" :options="bancos" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
            </div>
          </div>

		    <div class="row w-full  ">
              <div class=" col-md-8 align-blue">
              <vs-button class="btn-blue" @click.prevent="seleccionarTodos()" >Seleccionar todo</vs-button>
              
              <vs-button class="btn-blue" @click.prevent="limpiarBancos()" >Limpiar Selección</vs-button>
              </div>
        
            </div>

			 <div class="row w-full">
              <div
			  	v-if="caso.PrecalificacionTipo == 1" 
			  	class="col-md-4">
                <label class="span-placeholder" for="estatus">Perfil de cliente</label>
                <select name="cars" id="tasas" v-model="caso.tasa" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in tasas"  >{{tipo.Nombre}}</option>
                </select>
              </div>
             <div class="col-md-4">
                <span class="span-placeholder" for="estados">Estado</span>
                <select name="cars" id="estados" v-model="caso.estado" class="vs-input" @change="mostrarProgramas()">
                  <option :value='tipo.Id' v-for="tipo in estados"  >{{tipo.Nombre}}</option>
                </select>
              </div>
            </div>
            <br>

          <!-- cambio aportacion voluntaria -->
          <div
            v-if="hasAportacion == 'No'" 
            class="row w-full">
              <div class="col-md-4">
                <button class="btn btn-second" @click.prevent="ValidaSolicitante()" :disabled="!validateForm">Crear nuevo</button>
              </div>
          </div> 

        </div>


        <!-- start aportacion voluntaria section -->
        <div
			v-if="caso.PrecalificacionTipo == 1" 
			class="tab-pane show back fade" id="nav-aportacion" role="tabpanel" aria-labelledby="nav-aportacion-tab">
            
            <!-- row 1 -->
            <div class="row w-full">
                <div class="col-md-6">
                    <span class="span-placeholder" for="tipoEmpleo">Tipo de empleo</span>
                    <select 
                        v-model="tipoEmpleo"
                        name="tipoEmpleo" 
                        id="tipoEmpleo" 
                        class="vs-input">
                        <option :value="''">-- Seleccione --</option>
                        <option 
                            :value='empleo.Id' 
                            v-for="empleo in empleosList">{{ empleo.Nombre }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6">
                    <span class="span-placeholder" for="tipoContrato">Tipo de contrato</span>
                    <select 
                        v-model="tipoContrato"
                        name="tipoContrato" 
                        id="tipoContrato" 
                        class="vs-input">
                        <option :value="''">-- Seleccione --</option>
                        <option 
                            :value='contrato.Id' 
                            v-for="contrato in contratosList">{{ contrato.Nombre }}
                        </option>
                    </select>
                </div>
            </div>

            <!-- row 2 -->
            <div class="row w-full">
                <div class="col-md-6">
                    <span class="span-placeholder" for="ingresoBruto">Ingreso bruto</span>
                    <div>
                        <span class="symbol-currency col-12">
                            <input 
                                v-model="ingresoBruto"
                                type="text"
                                v-on:keyup="checkIngBruto()"
                                @change="setFormatIngBruto(); calcPercentIngreso()"
                                v-on:keypress="isNumber($event)"
                                class="vs-input input-currency"
                                id="ingresoBruto"
                                name="ingresoBruto"/>
                        </span>
                    </div>
                </div>

                <div class="col-md-6">
                    <span class="span-placeholder" for="ingresoNeto">Ingreso neto</span>
                    <div class="flex">
                        <span class="symbol-currency col-10">
                            <input 
                                v-model="ingresoNeto"
                                type="text"
                                v-on:keyup="checkIngNeto()"
                                @change="setFormatIngNeto(); calcPercentIngreso()"
                                v-on:keypress="isNumber($event)"
                                class="vs-input input-currency"
                                id="ingresoNeto"
                                name="ingresoNeto"/>
                        </span>
                        <span 
                            class="col-2 percent-box">
                            <p id="ingPercent">0 %</p>
                        </span>
                    </div>
                </div>
            </div>

            <!-- row 3 -->
            <div class="row w-full">
                <div class="col-md-6">
                    <span class="span-placeholder" for="tipoPrepago">Tipo prepago</span>
                    <select 
                        v-model="tipoPrepago"
                        name="tipoPrepago" 
                        id="tipoPrepago" 
                        class="vs-input">
                        <option :value="''">-- Seleccione --</option>
                        <option 
                            :value='prepago.Id' 
                            v-for="prepago in prepagoList">{{ prepago.Nombre }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6">
                    <span class="span-placeholder" for="aportacionVoluntaria">Aportación voluntaria</span>
                    <div>
                        <span class="symbol-currency col-12">
                            <input 
                                v-model="aportacionVoluntaria"
                                type="text"
                                v-on:keyup="checkApoVol()"
                                @change="setFormatApoVol()"
                                v-on:keypress="isNumber($event)"
                                class="vs-input input-currency"
                                id="aportacionVoluntaria"
                                name="aportacionVoluntaria"/>
                        </span>
                    </div>
                </div>
            </div>

            <!-- row 4 -->
            <div class="row w-full">
                <div class="col-md-6">
                    <span class="span-placeholder" for="tipoPlazo">Plazo</span>
                    <select 
                        v-model="tipoPlazo"
                        name="tipoPlazo" 
                        id="tipoPlazo" 
                        class="vs-input">
                        <option :value="''">-- Seleccione --</option>
                        <option 
                            :value='plazo.Id' 
                            v-for="plazo in plazosList">{{ plazo.Nombre }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6">
                    <span class="span-placeholder" for="numeroAportacion">Número de aportaciones</span>
                    <input 
                        v-model="numeroAportacion"
                        type="text"
                        
                        class="vs-input"
                        id="numeroAportacion"
                        name="numeroAportacion"/>

                    <span 
                        name="numAportaHelper"
                        id="numAportaHelper"
                        class="text-danger span-placeholder">
                        {{ helperCantidadAportacion }}
                    </span>
                </div>
            </div>


            <div
                v-if="hasAportacion == 'Si'" 
                class="row w-full">
                <div class="">
                    <button 
                        class="btn btn-second float-right" 
                        @click.prevent="ValidaSolicitante()" 
                        :disabled="!validateForm">Crear nuevo
                    </button>
                </div>
            </div>

        </div>
        <!-- end aportacion voluntaria section -->

      </div>
    </form>
  </div>
</template>

<script>
import Productos from '@/views/Caso/Productos.vue'
import Automotriz from '@/views/Destinos/Automotriz.vue';
import Adquisicion from '@/views/Destinos/Adquisicion.vue'
import Mejora from '@/views/Destinos/Mejora.vue'
import AdquisicionCofi from '@/views/Destinos/AdquisicionCofi.vue'
import AdquisicionAliados from '@/views/Destinos/AdquisicionAliados.vue'
import Terreno from '@/views/Destinos/Terreno.vue'
import TerrenoConstruccion from '@/views/Destinos/TerrenoConstruccion.vue'
import TerrenoProgramas from '@/views/Destinos/TerrenoProgrmas.vue'
import vSelect from 'vue-select'
export default {
	components: {
		Productos,
		Automotriz,
		Adquisicion,
		Mejora,
		AdquisicionCofi,
		AdquisicionAliados,
		Terreno,
		TerrenoConstruccion,
		'v-select': vSelect,
		TerrenoProgramas,
	},
	data() {
		return {
			autosDestinos: [],
			porcentajeActivaCotiza: true,
			PorcentajeMaximo: 100,
			productoTipoList: [],
            // start aportacion voluntaria
            hasAportacion: "No",
            empleosList: [],
            contratosList: [],
            plazosList: [],
            prepagoList: [],
            tipoEmpleo: '',
            tipoContrato: '',
            ingresoBruto: '',
            ingresoNeto: '',
            tipoPrepago: '',
            aportacionVoluntaria: '',
            tipoPlazo: '',
            numeroAportacion: '',
            helperCantidadAportacion: '',
            // end aportacion voluntaria

			items: [],
			email: '',
			nombre: '',
			apellidoMaterno: '',
			segNombre:'',
			apellidoPaterno: '',
			fechaNacimiento: '',
			telefono: '',
			curp:'',
			programas: [],
			accreditedId: '',
			caso: {
				valor: '',
				credito: '',
				destino: 1,
				plazos: [],
				esquemas: [],
				plazoRestante: '',
				mensualidad: '',
				programa: 1,
				bancos: [],
				gastosNotarial:6,
				montoCredito: 0,
				estado: '',
				tasa:2,
				PrecalificacionTipo: '',
				aniosACotizar: '',
				VersionOrigen: '',
			},
			bancosAuto: [
				{ label: "HSBC", value: 6 },
				{ label: "SCOTIABANK", value: 11 },
				{ label: "AFIRME", value: 12 },
				{ label: "HEY, BANCO", value: 14 },
			],
			 gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
            ],
			destinos: [],
			//bancosEjecutivo: [],
			 bancosEjecutivo:[{label:'CITIBANAMEX',value:2},
                      {label:'BANORTE',value:4},
                      {label:'HSBC',value:6},
                      {label:'SANTANDER',value:10},
                      {label:'SCOTIABANK',value:11}],
			bancos: [],
			otro: 0,
			estados: [],
			isActiveContentCiti: false,
			ligaCliente:true,
			header: '',
			SocGuid: '',
			BrokerId:'',
			IdLiga: '',
			token:'',
			Programa: '',
			// MINISTRACIONES EN COMPARADOR - JORGE SEP 2023
			usoMinistraciones: 'No',
			plazoContruccion: '',
			plazoMinistraciones: [
				{label: '-- Seleccione --', value: ''},
				{label: '3', value: '3'},
				{label: '4', value: '4'},
				{label: '5', value: '5'},
				{label: '6', value: '6'},
				{label: '7', value: '7'},
				{label: '8', value: '8'},
				{label: '9', value: '9'},
				{label: '10', value: '10'},
				{label: '11', value: '11'},
				{label: '12', value: '12'},
				{label: '13', value: '13'},
				{label: '14', value: '14'},
				{label: '15', value: '15'},
				{label: '16', value: '16'},
				{label: '17', value: '17'},
				{label: '18', value: '18'}
			],
			periodo: '',
			periodoMinistraciones: [
			{label: '-- Seleccione --', value: ''},
			/*{label : 'Mensual', value: 1},
			{label : 'Bimestral', value: 2},
			{label : 'Trimestral', value: 3},
			{label : 'Cuatrimestral', value: 4},
			{label : 'Semestral', value: 6},
			{label : 'Anual', value: 12}*/
			],
			campos: '',
			campo_1: 10,
			camposID: {
				name: []
			},
			banderaSave: false
		}
	},
	mounted() {
		this.getApikey()
		this.getDestinosAuto();
		this.getProductoTipos()
		// this.getDestinos()
		this.getBancos()
		this.getProgramas()
		//this.getBancosEjecutivo() era el original 14 02 2023
		this.getEstados()
		this.getTasas()
		this.DameCredenciales()

        this.getEmpleosList()
        this.getContratosList()
        this.getPrepagoList()
        this.getPlazoAportacionList()
		this.actualizaCampos()
	},
	computed: {
		emailState() {
			return this.email.includes('socasesores') ? true : false
		},
		validaCorreo(){
			const regex = /^[-\wñ.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      		const matches = regex.test(this.email);
			console.log("matches = "+ matches)
      		return matches ? true:false
		},
		validateForm() {
			if (this.usoMinistraciones == 'Si') {
				return this.email != '' 		&& 
                   this.nombre != '' 			&& 
                   this.apellidoPaterno != '' 	&& 
                   //this.apellidoMaterno != '' 	&& 
                   this.fechaNacimiento != '' 	&& 
                   this.telefono.length == 10 	&& 
                   this.caso.estado != '' 		&& 
                   !this.emailState 			&&
				   this.validaCorreo			&&
                   this.validateAportacion		&&
				   this.banderaSave &&
				   this.porcentajeActivaCotiza
			}else{
				return this.email != '' 		&& 
                   this.nombre != ''			&& 
                   this.apellidoPaterno != '' 	&& 
                   //this.apellidoMaterno != '' 	&& 
                   this.fechaNacimiento != '' 	&& 
                   this.telefono.length == 10 	&& 
                   this.caso.estado != '' 		&& 
                   !this.emailState 			&&
				   this.validaCorreo			&&
                   this.validateAportacion &&
				   this.porcentajeActivaCotiza
			}			
		},
        validateAportacion() {
            if (this.hasAportacion == "Si") {
                return this.tipoEmpleo != '' 			&&
                       this.tipoContrato != '' 			&&
                       this.ingresoBruto != '' 			&&
                       this.ingresoNeto != '' 			&&
                       this.tipoPrepago != '' 			&&
                       this.aportacionVoluntaria != '' 	&&
                       this.tipoPlazo != '' 			&&
                       this.numeroAportacion != ''
            }
            return true;
        }
	},
    watch: {
		// Formato mayusculas sin acento
		nombre: function(newValue, oldValue) {
			this.nombre = this.textFormat(newValue);
		},
		segNombre: function(newValue, oldValue) {
			this.segNombre = this.textFormat(newValue);
		},
		apellidoPaterno: function(newValue, oldValue) {
			this.apellidoPaterno = this.textFormat(newValue);
		},
		apellidoMaterno: function(newValue, oldValue) {
			this.apellidoMaterno = this.textFormat(newValue);
		},
		'caso.PrecalificacionTipo': function(newValue, oldValue) {
			// console.log( 'productoTipo', newValue );
			this.getDestinos(newValue);
		},
        // INFO: Sin dependencia de plazos de credito
        numeroAportacion: function(newValue, oldValue) {
            // console.log( 'newValue: ', newValue, 'oldValue: ', oldValue, this.numeroAporacion );
            if (this.hasAportacion == "Si") {
                // verifico si es un numero
                let regex = /^[0-9]*$/;
                if (!regex.test(newValue)) {
                    this.numeroAportacion = oldValue;
                    // console.log( 'er', this.numeroAportacion );
                }
                
                // valida el plazo de credito y el plazo de aportacion
                if (this.caso.plazos.length > 0 && this.tipoPlazo != '') {
                    if (!this.numeroAportacion) {
                        this.helperCantidadAportacion = `Escriba un valor correcto`;
                        return;
                    }
                    
                    // plazo mas grande
                    let plazo = Math.max(...this.caso.plazos);
                    let periodo = this.tipoPlazo;
                    let limiteAportacion = 0;
                    
                    // calcula el limite
                    if (plazo > 0 && periodo > 0) {
                        switch (periodo) {
                            case 1:
                                // mensual
                                limiteAportacion = 12 * plazo;
                                break;
                            case 2:
                                // bimestral
                                limiteAportacion = (12 / 2) * plazo;
                                break;
                            case 3:
                                // semestral
                                limiteAportacion = (12 / 6) * plazo;
                                break;
                            case 4:
                                // anual
                                limiteAportacion = plazo;
                                break;
                        }
                    }
                    
                    // limita el valor
                    if ( newValue > limiteAportacion) {
                        this.numeroAportacion = '';
                    }
                    
                    // mensaje
                    this.helperCantidadAportacion = `Tú límite de aportaciones es ${ limiteAportacion }`;
                    return;
                }
                
                // console.log( 'falta credito/plaza', typeof(this.numeroAportacion), this.numeroAportacion );
                this.helperCantidadAportacion = `Definir plazo de aportación y plazo a cotizar`;
                this.numeroAportacion = ''; // reinicia el valor
            }
        },
        hasAportacion: function(newValue, oldValue) {
            // reinicia el valor de los campos de aportacion
            if (newValue == "No") {
                this.tipoEmpleo = ''
                this.tipoContrato = ''
                this.ingresoBruto = ''
                this.ingresoNeto = ''
                this.tipoPrepago = ''
                this.aportacionVoluntaria = ''
                this.tipoPlazo = ''
                this.numeroAportacion = ''
                this.helperCantidadAportacion = '';

                this.calcPercentIngreso();
            }
        },
		'caso.destino': function(n, o) {
			if (this.autosDestinos.includes(n)) {
				this.getBancosAutoDestino();
			}
		}
    },
	methods: {
		getBancosAutoDestino() {
			let self = this;
			let objRequestBancosAutoDestino = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosAutoDestino',
				objCaso: {
					Destino: this.caso.destino,
				},
			}
			this.$axios
			.post('/', objRequestBancosAutoDestino, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
			.then((response) => {
				if (response.data.intEstatus == 0) {
					this.bancos = [];
					response.data.objContenido.forEach(banco => {
						this.bancos.push({ label: banco.Nombre, value: banco.Id })
					});
					this.caso.bancos = this.bancos;
				} else {
					this.$vs.notify({
						title: 'Ocurrio un error en ListaBancosAutoDestino',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
				}
			})
			.catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error en ListaBancosAutoDestino',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			})
		},
		getDestinosAuto() {
			let self = this
			let objRequestListaDestinosAuto = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinosAuto',
			}
			this.$axios.post('/', objRequestListaDestinosAuto, {headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						let objAutos = response.data.objContenido;
						objAutos.forEach(objAuto => {
							this.autosDestinos.push(objAuto.Id);
						});
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaDestinosAuto',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right'
						})
					}
				}
			).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position:'top-right'
				})
			})
		},
		// Conversion
		textFormat(strValue) {
			return strValue.normalize('NFD')
							.replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
							.normalize()
							.toUpperCase(); // Eliminar acentos menos Ññ
		},
		getProductoTipos() {
			let self = this;
			let objRequestProductoTipos = {
				strApiKey: this.key,
				strMetodo: 'DameProductoTipos',
			}
				
			this.$axios.post(
				'/', 
				objRequestProductoTipos, 
				{ headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
			).then((response) => {
				if (response.data.intEstatus == 0) {
					let productoList = response.data.objContenido;
					// retirar pyme, tarjeta, personal
					productoList.splice(1,1);
					productoList.splice(2,2);
					productoList[1].Nombre = "Auto";
					this.productoTipoList = productoList;

					// valor predeterminado
					this.caso.PrecalificacionTipo = 1;
				} else {
					this.$vs.notify({
						title: 'Ocurrio un error en DameProductoTipos',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
				}
			}).catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error en DameProductoTipos',
					text: error,
					color: 'danger',
					position: 'top-right',
				})
			})
		},
		//Ministraciones
		updatePeriodo(){		
			this.periodo = ''
			this.campos = ''
			document.getElementById('alert').style.display = 'none'

			if (this.plazoContruccion < 5) {
				this. periodoMinistraciones =  [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
				]
			}else if(this.plazoContruccion < 7){
				this.periodoMinistraciones= [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},					
				]
			}else if(this.plazoContruccion < 13){
				this.periodoMinistraciones = [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},
					{label : 'Semestral', value: 6},
					
				]
			}else{
				this.periodoMinistraciones = [
					{label: '-- Seleccione --', value: ''},
					{label : 'Mensual', value: 1},
					{label : 'Bimestral', value: 2},
					{label : 'Trimestral', value: 3},
					{label : 'Cuatrimestral', value: 4},
					{label : 'Semestral', value: 6},
					{label : 'Anual', value: 12}
				]
			}
		},

		updateCampos(){
			this.campos = ''
			this.banderaSave = false
			document.getElementById('alert').style.display = 'none'
			if (this.plazoContruccion && this.periodo) {
				this.campos = Math.floor(this.plazoContruccion / this.periodo)				
			}										
		},
		removeInputs(){
			document.getElementById("inputFormRow").remove();
		},
		calculaPorcentaje(){
			this.banderaSave = false;
			document.getElementById('alert').style.display = 'none'			
			var calcular = 0;

			for (let p = 1; p <= this.campos; p++) {			
				calcular += parseInt(this.camposID.name[p])				
			}
			
			if (calcular != 100) {
				document.getElementById('alert').style.display = 'block'
			}else{
				this.banderaSave = true;				
			}

		},
		actualizaCampos(){			
			for (let p = 1; p <= 18; p++) {
				this.camposID.name[p] = 0				
			}
		},
		SoloNumero: function(evt){
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				evt.preventDefault()
			} else {
				return true
			}
		},

        // start aportacion voluntaria
        getPrepagoList() {
			let self = this
			let objRequestPrepagoList = {
				strApiKey: this.key,
				strMetodo: 'ListaAportacionPrepagos',
			};

			this.$axios
				.post(
                        '/', 
                        objRequestPrepagoList, 
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
                    )
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.prepagoList = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaAportacionPrepagos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
        },
        getPlazoAportacionList() {
			let self = this
			let objRequestPlazoAportacionList = {
				strApiKey: this.key,
				strMetodo: 'ListaAportacionPlazos',
			};

			this.$axios
				.post(
                        '/', 
                        objRequestPlazoAportacionList, 
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
                    )
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.plazosList = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaAportacionPlazos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
        },
        getEmpleosList() {
			let self = this
			let objRequestEmpleosList = {
				strApiKey: this.key,
				strMetodo: 'ListaTipoEmpleos',
			};

			this.$axios
				.post(
                        '/', 
                        objRequestEmpleosList, 
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
                    )
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.empleosList = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoEmpleos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
        },
        getContratosList() {
			let self = this
			let objRequestContratosList = {
				strApiKey: this.key,
				strMetodo: 'ListaTipoContratos',
			}
			this.$axios
				.post(
                        '/', 
                        objRequestContratosList, 
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
                    )
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.contratosList = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoContratos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
        },
        checkIngBruto() {
            this.ingresoBruto = this.ingresoBruto.replace(/,/g,"")
            const regex = /^(\d{1,8})(\.\d{0,2})?$/  
            const matches = regex.test(this.ingresoBruto);
            if (matches === true){
            this.ingresoBruto = this.ingresoBruto
            } else{
                this.ingresoBruto = this.ingresoBruto.replace(/.$/, '')
            }
        },
        setFormatIngBruto() {
			if (this.ingresoBruto == '') { 
                this.ingresoBruto = 0 
            }
			this.ingresoBruto = parseFloat(this.ingresoBruto).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.ingresoBruto = this.ingresoBruto.toString().replace(exp, rep)
        },
        checkIngNeto() {
            this.ingresoNeto = this.ingresoNeto.replace(/,/g,"")
            const regex = /^(\d{1,8})(\.\d{0,2})?$/  
            const matches = regex.test(this.ingresoNeto);
            if (matches === true){
            this.ingresoNeto = this.ingresoNeto
            } else{
                this.ingresoNeto = this.ingresoNeto.replace(/.$/, '')
            }
        },
        setFormatIngNeto() {
			if (this.ingresoNeto == '') { 
                this.ingresoNeto = 0 
            }
			this.ingresoNeto = parseFloat(this.ingresoNeto).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.ingresoNeto = this.ingresoNeto.toString().replace(exp, rep)
        },
        checkApoVol() {
            this.aportacionVoluntaria = this.aportacionVoluntaria.replace(/,/g,"")
            const regex = /^(\d{1,8})(\.\d{0,2})?$/  
            const matches = regex.test(this.aportacionVoluntaria);
            if (matches === true){
            this.aportacionVoluntaria = this.aportacionVoluntaria
            } else{
                this.aportacionVoluntaria = this.aportacionVoluntaria.replace(/.$/, '')
            }
        },
        setFormatApoVol() {
			if (this.aportacionVoluntaria == '') { 
                this.aportacionVoluntaria = 0 
            }
			this.aportacionVoluntaria = parseFloat(this.aportacionVoluntaria).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.aportacionVoluntaria = this.aportacionVoluntaria.toString().replace(exp, rep)
        },
        setPlazoAportacion() {
            // al cambiar el plazo se reinicia el numero de aportaciones
            this.numeroAportacion = '';
            this.helperCantidadAportacion = '';
        },
        calcPercentIngreso() {
            const percentHtml = document.getElementById(`ingPercent`);
            let calculado = 0;
            let ingBruto = '';
            let ingNeto = '';

            if (typeof(this.ingresoBruto) == "string" && this.ingresoBruto != '') {
                ingBruto = this.ingresoBruto;
                ingBruto = ingBruto.replaceAll(',', '');
            } else {
                ingBruto = 0;
            }

            if (typeof(this.ingresoNeto) == "string" && this.ingresoNeto != '') {
                ingNeto = this.ingresoNeto;
                ingNeto = ingNeto.replaceAll(',', '');
            } else {
                ingNeto = 0;
            }

            // valor porcentual
            calculado = (parseInt(ingNeto) / parseInt(ingBruto)) * 100;
            // quitar decimales
            calculado = Math.trunc(calculado);

            if (!Number.isFinite(calculado)) {
                // console.log(  Number.isFinite(calculado), Number.isNaN(calculado) );
                calculado = 0;
            }

            percentHtml.innerHTML = `
                ${ calculado } %
            `;

        },
        // end aportacion voluntaria

		seleccionarTodos(){
		 this.caso.bancos=this.bancos
		}, 
		limpiarBancos(){
		 this.caso.bancos=[]
		},
		soloLetras: function(event) {
			var regex = new RegExp("^[áéíóúüña-zÑÁÉÍÓÚA-Z ]+$");
			var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
			if (!regex.test(key)) {
			event.preventDefault();
			return false;
			}
		},
		 getTasas(){
			let self=this
			var objRequestListaEstados = {
				strApiKey: this.key,
				strMetodo: 'ListaPerfilesCliente',
			}
			this.$axios.post('/',objRequestListaEstados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
				if(response.data.intEstatus == 0){
					this.tasas=response.data.objContenido
				}else {
					this.$vs.notify({
					title:'Ocurrio un error en ListaPerfilesCliente',
					text:response.data.strError,
					color:'danger',
					position:'top-right'
					})
				}
				}
			).catch(function (error) {
				self.$vs.notify({
				title:'Ocurrio un error de sistema',
				text:error,
				color:'danger',
				position:'top-right'
				})
			})
			},
		LigaCliente($valor){
			if ($valor) {
				setTimeout(() => this.ligaCliente = $valor, 200);	
			}
			else
				this.ligaCliente = $valor;
		},
		getEstados() {
			let self = this
			var objRequestListaEstados = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaEstados, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraAdicionales() {
			let self = this
			var objRequestAdicionales = {
				strApiKey: this.key,
				strMetodo: 'RegistraAdicionalesSolicitud',
				objCaso: {
					Id: this.id,
					InmuebleGarantia: {
						//InmuebleNuevo:this.caso.inmuebleNuevo,
						Estado: this.caso.estado,
					},
				},
			}
			this.$axios
				.post('/', objRequestAdicionales, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Guardado exitosamente`,
							position: 'top-right',
						})
						//this.dameCaso()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraAdicionalesSolicitud',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

		mostrarProgramas() {
			this.programas = []
			this.usoMinistraciones = 'No'

			// 1: Sin Programa || 6: Apoyo Infonavit || 4: Cofinamiento Infonavit || 9: Fovisste || 10: Fovisste para Todos

			if (this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 || this.caso.destino == 15) {
				this.programas = this.todosProgramas
			} 
			else if (this.caso.destino == 20) {

				this.programas = []
				var auxProgramas = [1, 6]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}

			} 
			else if ( this.caso.destino == 19 ) {

				this.programas = []
				var auxProgramas = [1, 6, 4, 9]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}

				const orderPrograms = auxProgramas.map(x => {
					const exist = this.programas.find(programa => x === programa.Id)

					return exist && exist
				})

				this.programas = orderPrograms

			}
			else {
				
				this.programas = []
				var auxProgramas = [1, 9, 4]
				var auxProgramaExiste = this.todosProgramas
				for (var j = 0; j < auxProgramaExiste.length; j++) {
					if (auxProgramas.includes(auxProgramaExiste[j]['Id'])) {
						this.programas.push(auxProgramaExiste[j])
					}
				}

			}

			if (this.caso.PrecalificacionTipo == 1) {
				var Destino = this.caso.destino
				this.cambiaBancos(Destino, 'destino')
			}
		},

		cambia() {
			this.otro = this.otro + 1
		},
		numberFormat(number) {
			return number
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		cambiaBancos(e, origen) {

			if (origen == 'destino') {
				this.bancos = []
				this.caso.destino = e;
			}
			else{
						this.bancos = []
						this.caso.plazos = []
						this.caso.esquemas = []
				

						for (var i = 0; i < e.plazosACotizar.length; i++) {
							if (typeof e.plazosACotizar[i] == 'object') {

								this.caso.plazos.push(parseInt(e.plazosACotizar[i].value))
						
							} else {
								this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
					
							}
						}
						for (var i = 0; i < e.esquemasACotizar.length; i++) {
							this.caso.esquemas.push(parseInt(e.esquemasACotizar[i].value))
						}
			}

			let self = this
			let objRequestDamePlazos = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosPlazos',
				objCaso: {
     				Destino: this.caso.destino,
					Esquema: this.caso.esquemas,
					Plazos: this.caso.plazos,
				},
			}
			this.$axios
				.post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
            
       ///   if(this.caso.plazos.length==1){
          /*  if(this.caso.plazos[0]=='25'){
               this.caso.bancos=[]

                this.caso.bancos.push({ label: 'BANORTE', value: 4 })
                 this.caso.bancos.push({ label: 'HSBC', value: 6 })
               
              	this.bancos.push({ label: 'BANORTE', value: 4 })
                this.bancos.push({ label: 'HSBC', value: 6 })
                
            }else{
             
                let bancos = response.data.objContenido
                for (var i = 0; i < bancos.length; i++) {
                  this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
                }
                 //// this.bancos.push({ label: 'KREDI', value: 33 })
                 //this.caso.bancos = this.bancosEjecutivo //cambio bancos
                  this.caso.bancos = this.bancos
           }*/

         ///  }else{
             
                let bancos = response.data.objContenido
                for (var i = 0; i < bancos.length; i++) {
                  this.bancos.push({ label: bancos[i].Nombre, value: bancos[i].Id })
                }
                  ////this.bancos.push({ label: 'KREDI', value: 33 })
              	  //this.caso.bancos = this.bancosEjecutivo //cambio bancos
                  this.caso.bancos = this.bancos

				/**
				* Solo se mostrara el banco banorte si el plazo es 21,22,24 sin combinar con otros plazos
				*/
				let checkPlazos = [];
				checkPlazos = this.caso.plazos;
				if (checkPlazos.length > 0 && checkPlazos.length < 4) { // solo puede tener 3 plazos max
					let findPlazo = this.caso.plazos.find(item => (item != 21 && item != 22 && item != 24));

					if (!findPlazo) { // si no se encontro otro plazo diferente a 21,22,24
						this.caso.bancos = [];
						this.bancos = [];
						this.caso.bancos.push({ label: 'BANORTE', value: 4 });
						this.bancos.push({ label: 'BANORTE', value: 4 });
					}
				}

        ////   }

 					}else if(response.data.intEstatus == 1){
        this.caso.bancos = [];
       } else {
        
 						this.$vs.notify({
 							title: 'Ocurrio un error en ListaFinancieras',
 							text: response.data.strError,
 							color: 'danger',
 							position: 'top-right',
 						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error en DamePlazos',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameBancosAuto(){ // no se usa
			if (this.caso.PrecalificacionTipo == 3) {
				let self = this
				let objRequestDamePlazos = {
				strApiKey: this.key,
				strMetodo: 'ListaBancosAutos'
				}
				this.$axios
				.post('/', objRequestDamePlazos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
					this.bancos = [];
					for (var i = 0; i < response.data.objContenido.length; i++) {
						this.bancos.push({ label: response.data.objContenido[i].Nombre, value: response.data.objContenido[i].Id })
					}
					this.caso.bancos = this.bancos
									
					}else {
					this.$vs.notify({
						title: 'Ocurrio un error en ListaBancosAutos',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right',
					})
				})
			}      
		},
		update(e) {	
			this.porcentajeActivaCotiza = true; // si deja inactivo auto cambiando a hipotecario
			
			if (e.otro) {
				this.otro = 0
			}
			this.caso.plazos = []
			this.caso.esquemas = []
			//this.caso.gastosNotarial=e.gastosNotarial
			for (var i = 0; i < e.plazosACotizar.length; i++) {
				if (typeof e.plazosACotizar[i] == 'object') {
					this.caso.plazos.push(e.plazosACotizar[i].value)
				} else {
					this.caso.plazos.push(e.plazosACotizar[i].substring(0, 2))
				}
			}
			for (var i = 0; i < e.esquemasACotizar.length; i++) {
				this.caso.esquemas.push(parseInt(e.esquemasACotizar[i].value))
			}
			if (e.destino == 25 || e.destino == 22 || e.destino == 33 || e.destino == 34 || e.destino == 19) {
			// if (e.destino == 19 || e.destino == 25 || e.destino == 22 || e.destino == 33 || e.destino == 34) {
				this.destino = e.destino
				this.caso.valor = e.valor
				this.caso.credito = e.credito
				this.caso.mensualidad = e.mensualidad
				this.caso.plazoRestante = e.plazoRestante
			} else if (e.destino == 11 || e.destino == 19) {
				this.caso.valorInmueble = e.valor
				this.caso.ssv = e.ssv
				this.caso.creditoInf = e.creditoInf
				this.caso.montoSolicitado = '0'
				if (this.caso.programa == 9 || this.caso.programa == 4) {
					this.caso.montoCredito = e.montoCredito
				}
			} else if (this.autosDestinos.includes(e.destino)) {
				this.caso.aniosACotizar = e.aniosACotizar;
				this.caso.valorInmueble = e.valor;
				this.caso.montoSolicitado = e.credito;
				this.caso.plazos = e.plazosACotizar;
				this.caso.destino = e.destino;
				
				if (e.activaCotizaMenor || e.activaCotizaMayor) { // si uno de los 2 es invalido
					this.porcentajeActivaCotiza = false;
				} else {
					this.porcentajeActivaCotiza = true;
				}
			} else {

				//alert('update'+ e.valor)
				this.destino = e.destino
				this.caso.valor = e.valor
				this.caso.credito = e.credito
			}
		},
		registrarSolicitante() {

            // quitar comas numeros
            let ingBruto = '';
            let ingNeto = '';

            if (this.hasAportacion == "Si") {
                ingBruto = this.ingresoBruto;
                ingNeto = this.ingresoNeto;

                // recalculo para casos especiales
                let plazo = Math.max(...this.caso.plazos);
                let periodo = this.tipoPlazo;
                let limiteAportacion = 0;
                
                // calcula el limite
                if (plazo > 0 && periodo > 0) {
                    switch (periodo) {
                        case 1:
                            // mensual
                            limiteAportacion = 12 * plazo;
                            break;
                        case 2:
                            // bimestral
                            limiteAportacion = (12 / 2) * plazo;
                            break;
                        case 3:
                            // semestral
                            limiteAportacion = (12 / 6) * plazo;
                            break;
                        case 4:
                            // anual
                            limiteAportacion = plazo;
                            break;
                        }
                }
                
                // limita el valor
                if ( this.numeroAportacion > limiteAportacion) {
                    this.numeroAportacion = '';
                    return; // No crear caso, por valores incorrectos
                }

                ingBruto = parseFloat(ingBruto.replaceAll(',', ''));
                ingNeto = parseFloat(ingNeto.replaceAll(',', ''));
            }

			let self = this
			this.$vs.loading({
				container: '#cotizacion',
				scale: 0.6,
			})
			let objRequestRegistraSolicitante = {
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitante',
				objSolicitante: {
					EMail: this.email,
					Nombres: this.nombre,
					ApellidoPaterno: this.apellidoPaterno,
					ApellidoMaterno: this.apellidoMaterno,
					SegundoNombre: this.segNombre,
					TelefonoCelular: this.telefono,
					FechaNacimiento: this.fechaNacimiento,
					StateData: 1,
					Curp: this.curp,
					AccreditedIdV3: this.IdLiga,
				},
				objAportacion: {
						// para validacion en el metodo
						hasAportacion: this.hasAportacion,
						// para bd
						tipoEmpleo: this.tipoEmpleo,
						tipoContrato: this.tipoContrato,
						ingresoBruto: ingBruto,
						ingresoNeto: ingNeto,
				},
				isCasoCompleto: true,
				objSolicitanteExtra: {
					CheckReplication: true,
				}
			}

			// return console.log('objRequestRegistraSolicitante :>> ', objRequestRegistraSolicitante);

			this.$validator.validateAll().then((result) => {
				if (result) {
					this.$axios
						.post('/', objRequestRegistraSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
						.then((response) => {

							// return console.log('response :>> ', response);

							if (response.data.intEstatus == 0) {
								this.registrarCaso()
							} else {
								this.$vs.notify({
									title: 'Ocurrio un error en RegistraSolicitante',
									text: response.data.strError,
									color: 'danger',
									position: 'top-right',
								})
							}
						})
						.catch(function (error) {
							self.$vs.notify({
								title: 'Ocurrio un error de sistema',
								text: error,
								color: 'danger',
								position: 'top-right',
							})
						})
				} else {
					this.$vs.notify({
						title: 'Ocurrio un error',
						text: 'Datos incompletos',
						color: 'danger',
						position: 'top-right',
					})
				}
			})
		},
		registrarCaso() {
			//Ministraciones

			let porcentajes = ''

			if (this.usoMinistraciones == 'Si') {
				porcentajes = this.camposID.name[1]

				for (let p = 2; p <= this.campos; p++) {													
					porcentajes = porcentajes + ',' + this.camposID.name[p]
				}
				porcentajes = porcentajes + ','						
			}else{
				this.plazoContruccion = ''
				this.periodo = ''	
				this.campos = ''				
			}

            // quitar comas numeros
            let aportaVol = '';
            let numAporte = '';
            if (this.hasAportacion == "Si") {
                aportaVol = this.aportacionVoluntaria;
                numAporte = this.numeroAportacion;

                aportaVol = parseFloat(aportaVol.replaceAll(',', ''));

                if (typeof(numAporte) == "string") {
                    numAporte = parseInt(numAporte.replaceAll(',', ''));
                }
                // numAporte = parseInt(numAporte.replaceAll(',', ''));
            }

			let self = this
			let objRequestRegistraCaso1 = {}
			let bancos = []
			if (this.caso.montoCredito !== 0 && this.caso.PrecalificacionTipo != 3) {
				//no usar replace cuando es 0
				var auxMontoCredito = parseFloat(this.caso.montoCredito.replace(/,/g, ''))
				this.caso.montoCredito = auxMontoCredito
			}
			for (var i = 0; i < this.caso.bancos.length; i++) {
				bancos.push(parseInt(this.caso.bancos[i].value))
			}
			//if (this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34') {
			// if (this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34') {
			if((this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34')  && ( this.caso.programa == 6 || this.caso.programa == 1) ){
				objRequestRegistraCaso1 = {
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						ValorInmueble: parseFloat(this.caso.valor.replace(/,/g, '')),
						//MontoSolicitado : parseFloat(this.caso.credito.replace(/,/g,"")),
						SaldoInsoluto: parseFloat(this.caso.credito.replace(/,/g, '')),
						Plazos: this.caso.plazos,
						Solicitante: this.email,
						Destino: this.caso.destino,
						Esquemas: this.caso.esquemas,
						Programas:[this.caso.programa],
						PlazoRestante: this.caso.plazoRestante,
						PagoMensualActual: parseFloat(this.caso.mensualidad.replace(/,/g, '')),
						Bancos: bancos,
						PerfilCliente : this.caso.tasa,
						VersionOrigen: 'Web completa',
						GastoNotarial: this.caso.gastosNotarial,
						EstadoInmueble: this.caso.estado,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
                    objAportacion: {
                        // para validacion en el metodo
                        hasAportacion: this.hasAportacion,
                        // para bd
                        prepagoAportacion: this.tipoPrepago,
                        plazoAportacion: this.tipoPlazo,
                        montoAportacion: aportaVol,
                        cantidadAportaciones: numAporte,
                    },
				}
			} else if((this.caso.destino == '19' || this.caso.destino == '25' || this.caso.destino == '22' || this.caso.destino == '33' || this.caso.destino == '34')  && ( this.caso.programa == 4 || this.caso.programa == 9) ){                  
				objRequestRegistraCaso1 = {
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						ValorInmueble: parseFloat(this.caso.valor.replace(/,/g, '')),
						//MontoSolicitado : parseFloat(this.caso.credito.replace(/,/g,"")),
						SaldoInsoluto: parseFloat(this.caso.credito.replace(/,/g, '')),
						Plazos: this.caso.plazos,
						Solicitante: this.email,
						Destino: this.caso.destino,
						Esquemas: this.caso.esquemas,
						Programas:[this.caso.programa],
						PlazoRestante: this.caso.plazoRestante,
						PagoMensualActual: parseFloat(this.caso.mensualidad.replace(/,/g, '')),
						CreditoInfonavit:parseFloat(this.caso.creditoInf.replace(/,/g,"")),
            			SubCuentaVivienda:parseFloat(this.caso.ssv.replace(/,/g,"")),
						Bancos: bancos,
						PerfilCliente : this.caso.tasa,
						VersionOrigen: 'Web completa',
						GastoNotarial: this.caso.gastosNotarial,
						EstadoInmueble: this.caso.estado,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
                    objAportacion: {
                        // para validacion en el metodo
                        hasAportacion: this.hasAportacion,
                        // para bd
                        prepagoAportacion: this.tipoPrepago,
                        plazoAportacion: this.tipoPlazo,
                        montoAportacion: aportaVol,
                        cantidadAportaciones: numAporte,
                    },
				}
			}
			else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2) && (this.caso.programa == 6 || this.caso.programa == 1) ) {
				//|| this.caso.destino == 20 quitamos terreno y construccion
				objRequestRegistraCaso1 = {
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						ValorInmueble: parseFloat(this.caso.valor.replace(/,/g, '')),
						MontoSolicitado: parseFloat(this.caso.credito.replace(/,/g, '')),
						Plazos: this.caso.plazos,
						Solicitante: this.email,
						Destino: this.caso.destino,
						Esquemas: this.caso.esquemas,
						Bancos: bancos,
						Programas: [this.caso.programa],
						PerfilCliente : this.caso.tasa,
						VersionOrigen: 'Web completa',
						GastoNotarial: this.caso.gastosNotarial,
						EstadoInmueble: this.caso.estado,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
                    objAportacion: {
                        // para validacion en el metodo
                        hasAportacion: this.hasAportacion,
                        // para bd
                        prepagoAportacion: this.tipoPrepago,
                        plazoAportacion: this.tipoPlazo,
                        montoAportacion: aportaVol,
                        cantidadAportaciones: numAporte,
                    },
					objMinistraciones:{
						usoMinistraciones: this.usoMinistraciones,
						Plazo: this.plazoContruccion,
						Periodo: this.periodo,
						Porcentajes : porcentajes
					}
				}
			} else if ((this.caso.destino == 1 || this.caso.destino == 5 || this.caso.destino == 2 || this.caso.destino == 20) && (this.caso.programa == 4 || this.caso.programa == 9)) {
				objRequestRegistraCaso1 = {
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						ValorInmueble: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
						MontoSolicitado: 0,
						Plazos: this.caso.plazos,
						Solicitante: this.email,
						Destino: this.caso.destino,
						CreditoInfonavit: parseFloat(this.caso.creditoInf.replace(/,/g, '')),
						SubCuentaVivienda: parseFloat(this.caso.ssv.replace(/,/g, '')),
						Esquemas: this.caso.esquemas,
						Bancos: bancos,
						Programas: [this.caso.programa],
						PerfilCliente : this.caso.tasa,
						VersionOrigen: 'Web completa',
						MontoCredito: this.caso.montoCredito,
						GastoNotarial: this.caso.gastosNotarial,
						EstadoInmueble: this.caso.estado,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
                    objAportacion: {
                        // para validacion en el metodo
                        hasAportacion: this.hasAportacion,
                        // para bd
                        prepagoAportacion: this.tipoPrepago,
                        plazoAportacion: this.tipoPlazo,
                        montoAportacion: aportaVol,
                        cantidadAportaciones: numAporte,
                    },
					objMinistraciones:{
						usoMinistraciones: this.usoMinistraciones,
						Plazo: this.plazoContruccion,
						Periodo: this.periodo,
						Porcentajes : porcentajes
					}
				}
			} else if (this.autosDestinos.includes(this.caso.destino)) {
				// formato de plazos
				let plazos = this.caso.plazos;
				this.caso.plazos = [];
				
				plazos.forEach(plazo => {
					if (typeof plazo == 'object') {
					this.caso.plazos.push(parseInt(plazo.value));
					} else if (typeof plazo == 'string') {
					this.caso.plazos.push(parseInt(plazo));
					} else {
					let ps = 6;
					if (plazo > 0) {
						ps = plazo * 12;
					}
					this.caso.plazos.push(ps);
					}
				});

				objRequestRegistraCaso1 = {
					strApiKey: this.key,
					strMetodo: 'RegistraCasoAuto',
					objCaso: {
						Broker: this.brokerEmail,
						Destino: this.caso.destino,
						ValorVehiculo: parseFloat(this.caso.valorInmueble.replace(/,/g, '')),
						MontoEnganche: parseFloat(this.caso.montoSolicitado.replace(/,/g, '')),
						Anio: parseInt(this.caso.aniosACotizar),
						Plazos: this.caso.plazos,
						Solicitante: this.email,
						Esquemas: [2], // fijo
						Bancos: bancos,
						VersionOrigen: 'Web completa',
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					}
				}
				// console.log( 'AutomotrizNuevo', objRequestRegistraCaso1 );
				// return;
			} else {

			///	alert('Nuevo Caso'+this.caso.valor)
				objRequestRegistraCaso1 = {
					strApiKey: this.key,
					strMetodo: 'RegistraCaso',
					objCaso: {
						ValorInmueble: parseFloat(this.caso.valor.replace(/,/g, '')),
						MontoSolicitado: parseFloat(this.caso.credito.replace(/,/g, '')),
						Plazos: this.caso.plazos,
						Solicitante: this.email,
						Destino: this.caso.destino,
						Esquemas: this.caso.esquemas,
						Bancos: bancos,
						Programas: [this.caso.programa],
					    PerfilCliente : this.caso.tasa,
						VersionOrigen: 'Web completa',
						GastoNotarial: this.caso.gastosNotarial,
						EstadoInmueble: this.caso.estado,
						PrecalificacionTipo: this.caso.PrecalificacionTipo
					},
                    objAportacion: {
                        // para validacion en el metodo
                        hasAportacion: this.hasAportacion,
                        // para bd
                        prepagoAportacion: this.tipoPrepago,
                        plazoAportacion: this.tipoPlazo,
                        montoAportacion: aportaVol,
                        cantidadAportaciones: numAporte,
                    },
					objMinistraciones:{
						usoMinistraciones: this.usoMinistraciones,
						Plazo: this.plazoContruccion,
						Periodo: this.periodo,
						Porcentajes : porcentajes
					}
				}
			}
			this.$axios
				.post('/', objRequestRegistraCaso1, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {

						if (this.caso.PrecalificacionTipo == 1) {
							this.id = response.data.objContenido.Id;
							this.registraAdicionales();
							this.$vs.loading.close('#cotizacion > .con-vs-loading');
							this.$router.push('/caso/' + response.data.objContenido.Id).catch(() => {});
						} else if (this.caso.PrecalificacionTipo == 3) {
							this.id = response.data.objContenido.Precalificacion;
							this.registraAdicionales();
							this.$vs.loading.close('#cotizacion > .con-vs-loading');
							this.$router.push('/caso/' + response.data.objContenido.Precalificacion).catch(() => {});
						}
						// this.id = response.data.objContenido.Id;
						// this.registraAdicionales();
						// this.$vs.loading.close('#cotizacion > .con-vs-loading');
						// this.$router.push('/caso/' + response.data.objContenido.Id).catch(() => {});

					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getProgramas() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaProgramas',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.programas = response.data.objContenido
						this.todosProgramas = this.programas
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaProgramas',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getDestinos(strValue) {
			let self = this
			var objRequestListaDestinos = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinos',
			}
			this.$axios
				.post('/', objRequestListaDestinos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						// let destinos = response.data.objContenido
						// for (var i = 0; i < destinos.length; i++) {
						// 	if (destinos[i].Id != 31) {
						// 		this.destinos.push(destinos[i])
						// 	}
						// }
						let vctDestinos = response.data.objContenido;
						switch (strValue) {
							case 1:  // hipotecario, no muestra tipo automotriz ni pyme
								this.destinos = vctDestinos.filter(item => item.Tipo != 10 && item.Tipo != 11 && item.Id != 31);
								break;
							case 3: // auto, muestra solo automotriz
								this.destinos = vctDestinos.filter(item => item.Tipo == 10);
								break;
						}

						/**
						 * Si los destinos de la linea de negocio no tienen el destino actual se pone uno por defecto
						 * Hipotecario = asignar destino 1
						 * Auto = asignar destino 41
						 * 
						 * Se ejecuta antes de la asignacion de destino del componente Automotriz.vue
						 **/
						let searchDestino = this.destinos.find((item) => item.Id == this.caso.destino);
						if (!searchDestino) {
							if (this.caso.PrecalificacionTipo == 1) {
								this.caso.destino = 1;
							} else if (this.caso.PrecalificacionTipo == 3) {
								this.caso.destino = 41;
							}
						}

						// console.log( 'searchDestino', searchDestino );
						// console.log( 'searchDestin', this.caso.PrecalificacionTipo );
						// console.log( 'searchDestin', this.caso.destino );

						if (this.caso.PrecalificacionTipo == 1) {
							/**
							 * Se obtiene la lista de bancos completa
							 */
							this.bancos = []
							this.getBancos();
							// console.log( 'BancosHipotecario1', this.bancos );

						} else if (this.caso.PrecalificacionTipo == 3) {
							/**
							 * Solo listar bancos disponibles actualmente para autos, hacer dinamica despues
							 */
							//this.bancos = [];
							//this.bancos = this.bancosAuto;
							// this.dameBancosAuto()
							this.getBancosAutoDestino();

							// coincidencias de caso.bancos en bancoAuto
              				/*let cloneCasoBancos = this.caso.bancos;
							this.caso.bancos = [];
							cloneCasoBancos.forEach(banco => {
								let s = this.bancosAuto.find(b => b.value == banco.value);
								if (s) {
									this.caso.bancos.push(banco);
								}
							});*/

							// console.log( 'BancosHipotecario3A', this.bancosAuto );
							// console.log( 'BancosHipotecario3B', this.bancos );
						}

					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaDestinos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		 getBancos(){
    var data = {
         destino: this.caso.destino,
         esquemasACotizar: [{
           label:"Pago Fijo",
           value: 2
         }],
         plazosACotizar:[{
           label: "20 Años",
           value: 20
         }]            
       }           

       this.cambiaBancos(data)
			/*let self=this
			var objRequestListaFinancieras = {
				strApiKey: this.key,
				strMetodo: 'ListaFinancieras',
				objEjecutivo: {
				EMail: this.$store.state.AppActiveUser.EMail
				}
			}
			this.$axios.post('/',objRequestListaFinancieras,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
				if(response.data.intEstatus == 0){
					let bancos=response.data.objContenido
					for (var i = 0; i < bancos.length; i++) {
					this.bancos.push({label:bancos[i].Nombre, value: bancos[i].Id})
					}
					this.caso.bancos=this.bancosEjecutivo //cambio bancos
				}else {
					this.$vs.notify({
					title:'Ocurrio un error en ListaFinancieras',
					text:response.data.strError,
					color:'danger',
					position:'top-right'
					})
				}
				}
			).catch(function (error) {
				self.$vs.notify({
				title:'Ocurrio un error de sistema',
				text:error,
				color:'danger',
				position:'top-right'
				})
			})*/
			},
		getBancosEjecutivo() {
			let self = this
			var objRequestListaFinancieras = {
				strApiKey: this.key,
				strMetodo: 'ListaFinancieras',
				objEjecutivo: {
					EMail: this.$store.state.AppActiveUser.EMail,
				},
			}
			this.$axios
				.post('/', objRequestListaFinancieras, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						let bancos = response.data.objContenido
						for (var i = 0; i < bancos.length; i++) {
							this.bancosEjecutivo.push({ label: bancos[i].Nombre, value: bancos[i].Id })
						}
						this.caso.bancos = this.bancosEjecutivo
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaFinancieras',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		getApikey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey;
				this.brokerEmail = JSON.parse(localStorage.getItem('userInfo')).EMail; // registro caso auto
			}
		},
		ValidaSolicitante() {
			let self = this

			if (this.curp != '') {
					//console.log("curp contiene ALGUN VALOR")
					//this.GeneraLiga()

					if(this.$store.state.AppActiveUser.OrganizacionTipo!=2){
						this.dameTokenV3();
					}else{
						this.registrarSolicitante()	
					}
					
			} else {
				console.log("CURP es vacio");
				this.registrarSolicitante()				
			}
		},
		dameTokenV3(){

			let objRequestDameTokenPv3 = {
			strApiKey: this.key,
			strMetodo: 'DameTokenPv3'
			}
			this.$axios.post('/', objRequestDameTokenPv3, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response) {
						this.token = response.data.access_token;
						this.GeneraLiga();
					}
					else{
						this.$vs.notify({
							title: 'Ocurrio un error en Obtener token de Pv3',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		GeneraLiga(){
			let self=this
		
			var mostrar = ""
			let objRequestCotizaCaso =
				{
				AccreditedModel: {
					Name: this.nombre,
					SName: this.segNombre,
					LastName: this.apellidoPaterno,
					SecondLastName: this.apellidoMaterno,
					PersonalMail: this.email,
					CellPhone: this.telefono,
					CURP: this.curp,
					RFC: null,  
					CivilStatus: null,
					Nationality: null,
					Gender: null,
					Age: null,
					StudyGrade: null,
					EconomicDependents: null,
					NumberOfChildren: null,
					ChildrensAge: null,
					CP: null,
					MonetaryIncome: null,
					NumberOfCars: null,
					Activity: null,
					PhoneHome: null,
					DateBirtday: this.fechaNacimiento,
					Cocredited: null,
					Weight: null,
					Height: null,
					NSS: null,
					TypeHousing: null,
					ResidenceTimeHome: null,
					ResidenceTimeTown: null,
					CompanyName: null,
					CompanyAddress: null,
					Position: null,
					TypeCompany: null,
					WorkingTime: null,
				},
				ReferencesModel: {
					Name: null,
					SName: null,
					LastName: null,
					SecondLastName: null,
					Phone: null,
					Relationship: null,
					TimeRelationship: null
				},
				CreditRequestModel: {
					TypeCredit: null,
					HomeValue: null,
					CreditAmount: null,
					Plazo: null
				},
				BrokerEmployeeModel: {
					Id: this.SocGuid,
					BrokerId: this.BrokerId,
					EmployeeName: null,
					EmployeeEmail: null,
					Origin: "Cotizador soc"
					
				},
				IsQuickQuote:true,
			}
			var Token = "Bearer "+this.token
			this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestCotizaCaso,{headers : {"authorization": Token}})
			.then(
			response => {
				this.$vs.loading({
					container: '#cotizacion',
					scale: 0.6
				}) 
				//document.getElementById('btnGuardar').style.display='none' 
				this.IdLiga = response.data.AccreditedId           
				this.registrarSolicitante()    
				this.EnviaCorreo()
				
			}
			).catch(function (error) {
				if(error.response.data.Message == "The CellPhone already exists"){
							mostrar = "El telefono ya se encuentra registrado intente con otro"
				}else if(error.response.data.Message == "The email already exists"){
					mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
				}else{
					mostrar = error
				}
				
				self.$vs.notify({
					title:'Ocurrio un error en SISEC',
					text:mostrar,
					color:'danger',
					position:'top-right'
				})
			})	
			
		},
		DameCredenciales(){
		let self=this
		let objRequestDameCredenciales = {
			strApiKey: this.key,
			strMetodo: 'DameCredenciales',
			objBroker: {
			ApiKey: this.key
			//ApiKey: '0f4ea774ac558fad084e47a7b7f6f580' //solo pruebas
			}
		}
		this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
		.then(
			response => {
			this.SocGuid = response.data.objContenido.SocGuid
			this.BrokerId = response.data.objContenido.BrokerId

			//this.SocGuid = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';
          	//this.BrokerId = 'f5b58eb5-4f2e-49c1-ae90-0fcab572c879';
			}
		).catch(function (error) {
			self.$vs.notify({
			title:'Ocurrio un error de sistema',
			text:error,
			color:'danger',
			position:'top-right'
			})
		})
		},
		EnviaCorreo(){
			let self=this
			let objRequestEnviaLigaCorreo = {
				Nombre: this.nombre,
				Id: this.IdLiga,
				Correo: this.email
			}

			this.$vs.notify({
				title:'Enviando Correo',
				position:'top-right'
			})

			this.$axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/EmailLigaCliente.php',objRequestEnviaLigaCorreo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
				.then(
				response => {

					this.$vs.notify({
					title:'Se envió correctamente el correo electrónico a',
					text:this.email,
					position:'top-right'
					})

				}
				).catch(function (error) {
				self.$vs.notify({
					title:'Ocurrio un error de sistema',
					text:error,
					color:'danger',
					position:'top-right'
				})
				})
		},
	},
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.card__header{
  border:none !important;
  margin-left: 13px;
}
.liga_cliente{
	background:white; 
	border-radius: 1.5%;	
	margin-top:40px;
	margin-bottom: 20px;
}
.liga_cliente .row{
	margin-left: 13px;
	font-family: Arial, Helvetica, sans-serif;
  	color: black;
}

/* aportacion voluntaria */
.symbol-currency {
    display: inline-block;
    position: relative;
}
.symbol-currency:before {
    content: "$";
    position: absolute;
    left: 8px;
    top: 60%;
    transform: translateY(-50%);
    z-index: 101;
}
.input-currency {
    padding-left: 20px;
}
#ingresoNeto {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.percent-box {
    margin-top: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: gray;
}
.percent-box p {
    color: white;
    font-size: medium;
    margin: 0;
    text-align: center;
    padding-top: 6px;
}
</style>
